export const IMAGE = {
    bath : require('../Assets/BathIcon.svg').default,
    bed : require('../Assets/BedIcon.svg').default,
    area : require('../Assets/SquareFeetIcon.svg').default,
    buy : require('../Assets/BuyAHome.svg').default,
    rent : require('../Assets/RentAHome.svg').default,
    neighbor : require('../Assets/Neighborhoods.svg').default,
    sample : require('../Assets/sample.jpg'),
    stat1 : require('../Assets/stat1.png'),
    sample1 : require('../Assets/trimbakeshwar.jpg'),

}
